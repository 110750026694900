<template>
    <styled-tooltip
        v-model="tooltipOpen"
        position="bottom"
        :max-width="320"
        open-on-click>
        <v-progress-circular
            v-if="loading"
            color="primary"
            width="2"
            size="15"
            indeterminate />

        <span
            v-else
            class="budget-edit"
            :class="{
                'budget-is-set': !!currentBudget
            }">
            <a
                v-if="!hideBudget && currentBudget && !loading"
                class="mr-1"
                @click="tooltipOpen = true">
                <span>{{ currentBudget | numFormat(numberFormats.currency) }}</span>
            </a>
            <a 
                v-else-if="noDataLabel"
                class="mr-1"
                @click="tooltipOpen = true">
                <span>{{ noDataLabel }}</span>
            </a>
            <action-button
                icon="edit"
                :size="iconSize"
                @click="tooltipOpen = true" />
        </span>


        <template #content>
            <div :style="{ mindWidth: '270px' }">
                <v-alert
                    :value="error"
                    type="error">
                    Error applying update.  Please try again or contact support if the problem persists.
                </v-alert>
                <v-alert
                    :value="!loading && complete"
                    type="success">
                    Update successfully applied!
                </v-alert>
                <v-form
                    id="edit-budget-form"
                    v-model="valid"
                    @submit.prevent="handleSubmit">
                    <v-text-field
                        v-model.trim="newBudget"
                        label="Monthly Budget"
                        :rules="[...requiredRules, ...currencyRules]"
                        hint="Enter new budget for this account"
                        class="py-3 my-0"
                        required />

                    <div class="text-xs-center">
                        <v-btn
                            type="submit"
                            form="edit-budget-form"
                            :loading="loading"
                            :disabled="!valid"
                            depressed
                            outline
                            color="primary"
                            class="text-none font-weight-bold">
                            Save
                        </v-btn>
                    </div>
                </v-form>
                <a
                    v-if="currentBudget"
                    class="clear-budget"
                    href="#"
                    @click.prevent="handleClearBudget">
                    Clear Budget
                </a>
            </div>
        </template>
    </styled-tooltip>
</template>

<script>
import { requiredRules, currencyRules } from '../../helpers/validationRules';
import getNumberFormats from  '../../helpers/numberFormats';
import ActionButton from './ActionButton';
import StyledTooltip from './StyledTooltip';

export default {
    components: {
        ActionButton,
        StyledTooltip
    },
    props: {
        dealer: {
            type: Object,
            required: true
        },
        hideBudget: {
            type: Boolean,
            default: false
        },
        iconSize: {
            type: String,
            default: '12'
        },
        noDataLabel: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            requiredRules,
            error: null,
            complete: false,
            valid: false,
            loading: false,
            tooltipOpen: false,
            newBudget: '',
            currentBudget: '',
            numberFormats: getNumberFormats()
        };
    },
    watch: {
        dealer() {
            this.currentBudget = this.getBudget();
            this.newBudget = this.getBudget();
        }
    },
    created() {
        // @todo allow budgeting to function internationally
        this.currencyRules = currencyRules('US');
        this.currentBudget = this.getBudget();
        this.newBudget = this.getBudget();
    },
    methods: {
        handleClearBudget() {
            this.updateBudget('');
        },
        async handleSubmit() {


            /* setTimeout(() => {
                this.loading = false;
                this.complete = true;

                this.$emit('update', this.newBudget);

                setTimeout(() => {
                    this.complete = false;
                    this.tooltipOpen = false;
                }, 1000);
            }, 3000); */

            this.updateBudget(this.newBudget);
        },
        async updateBudget(budget) {
            this.error = null;
            this.complete = false;
            this.loading = true;

            try {

                await this.$http.patch(`/dealers/${this.dealer.id}`, { budget });

                this.$emit('update', { budget, dealer: this.dealer });
                this.currentBudget = budget;
                this.loading = false;

                this.complete = true;
                setTimeout(() => {
                    this.tooltipOpen = false;
                    this.complete = false;
                    // If this is a budget reset ensure the newBudget
                    // is cleared to reset the field
                    if (!budget) {
                        this.newBudget = '';
                    }
                }, 2000);

            } catch(error) {
                // eslint-disable-next-line no-console
                console.error('Error updating dealer budget:', error);
                this.error = true;
            } finally {
                this.loading = false;
            }
        },
        getBudget() {
            return this.dealer.budget || '';
        },
    }
};
</script>

<style lang="scss" scoped>
.clear-budget {
    color: $carnation;
    margin-top: 20px;
    display: inline-block;
}
</style>
