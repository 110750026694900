<template>
    <styled-interface>
        <metric-info-slideout v-model="showMetricInfo" />

        <template #interface-toolbar-external>
            <dealer-toolbar-global />
        </template>
        <div class="layout wrap">
            <global-alert />

            <div class="dealer-status">
                <div class="item account">
                    <p class="label">
                        Account:
                    </p>
                    <p class="value">
                        {{ currentDealer.name }}
                    </p>
                </div>
                <div
                    v-if="!userIsClient"

                    class="item product">
                    <p class="label">
                        Active Products:
                    </p>
                    <p class="value">
                        {{ productsFormatted }}
                    </p>
                </div>
                <div class="item deployment">
                    <p class="label">
                        Active Deployment:
                    </p>
                    <p
                        v-if="activeDeployment"
                        class="value">
                        <a
                            href="#"
                            @click.prevent="gotoOnboarding">{{ activeDeployment.label }}</a>
                    </p>
                    <p
                        v-else
                        class="value">
                        None at this time
                    </p>
                </div>
                <div class="item actions">
                    <action-button
                        v-if="!hideSpend"
                        icon="pdf"
                        @click="showGenerateReportSlideout = true">
                        Generate
                    </action-button>
                    <!-- <action-button
                        icon="refresh"
                        @click="refresh">
                        Refresh
                    </action-button> -->
                    <action-button
                        v-if="!userIsClient"
                        class="ml-4"
                        icon="cog"
                        :to="{name: 'dealer'}">
                        Account Settings
                    </action-button>
                </div>
            </div>
            <onboarding-card
                v-if="!userIsClient"
                class="flex xs12"
                :dealer="currentDealer" />
            <overall-status
                v-if="!userIsClient" />
            <no-advertising-data
                v-if="!insightsLoading && !hasInsights && dealerHasAdvertising" />
            <metric-funnel
                v-if="hasInsights && dealerHasAdvertising"
                @show-info="showMetricInfo = true" />
            <budget-pacing v-if="showBudgeting" />
            <facebook-ad-stats
                v-if="hasInsights && dealerHasAdvertising && !hideSpend" />
        </div>
        <generate-report-slideout
            v-model="showGenerateReportSlideout"
            :template-id="FACEBOOK_REPORT_ID" />
    </styled-interface>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import MetricInfoSlideout from './metric-funnel/MetricInfoSlideout';
import ActionButton from '@/components/globals/ActionButton';
import GlobalAlert from '@/components/globals/GlobalAlert';
// import ProductUpgradeCta from './ProductUpgradeCta';
import StyledInterface from '@/components/globals/StyledInterface';
import OnboardingCard from '@/components/dealers/DealerID/OnboardingCard';
import OverallStatus from './cards/OverallStatus';
import NoAdvertisingData from './cards/NoAdvertisingData';
import MetricFunnel from './metric-funnel/MetricFunnel';
import BudgetPacing from './cards/BudgetPacing';
import FacebookAdStats from '@/components/globals/FacebookAdStats';
import DealerToolbarGlobal from '@/components/globals/DealerToolbarGlobal';
import GenerateReportSlideout from '@/components/report-builder/GenerateReportSlideout';
import { FACEBOOK_REPORT_ID } from '@/helpers/globals';

export default {
    name: 'FacebookDashboard',
    title: 'Facebook Dashboard',
    components: {
        DealerToolbarGlobal,
        ActionButton,
        // ProductUpgradeCta,
        StyledInterface,
        OnboardingCard,
        OverallStatus,
        NoAdvertisingData,
        MetricFunnel,
        BudgetPacing,
        FacebookAdStats,
        MetricInfoSlideout,
        GlobalAlert,
        GenerateReportSlideout
    },
    data() {
        return {
            missingAdvertisingData: false,
            showGenerateReportSlideout: false,
            showMetricInfo: false,
            FACEBOOK_REPORT_ID
        };
    },
    computed: {
        ...mapState({
            currentDealer: state => state.dealer.currentDealer
        }),
        ...mapGetters([
            'dealerProducts',
            'dealerHasFeatures',
            'activeDealerOnboardings',
            'userIsClient'
        ]),
        ...mapGetters('metrics', [
            'hideSpend'
        ]),
        ...mapGetters('metrics/facebook/ads', {
            hasInsights: 'hasMetrics',
            insightsLoading: 'metricsLoading'
        }),
        dealerHasAdvertising() {
            return this.dealerHasFeatures(['facebook_ad_account']);
        },
        showBudgeting() {

            let allowedAgencyIds = [2]; // Agency IDs we're enabling this for

            if(
                allowedAgencyIds.includes(this.currentDealer.agency_id) &&
                !this.userIsClient &&
                this.hasInsights &&
                this.dealerHasAdvertising &&
                !this.hideSpend
            ) {
                // Data can be shown at this point
                return true;
            }
            return false;
        },
        productsFormatted() {
            let names = this.dealerProducts
            .filter(p => p.platform_id == 1)
            .map(p => p.display_name);
            if(names.length == 1) {
                return names[0];
            } else if (names.length == 2) {
                return names.join(', ');
            }
            return names;
        },
        activeDeployment() {
            if(this.activeDealerOnboardings.length > 0) {
                return this.activeDealerOnboardings[0];
            }
            return false;
        }
    },
    methods: {
        refresh() {
            this.$store.dispatch('updateCurrentDealer');
        },
        gotoOnboarding() {
            if (this.userIsClient) {
                this.$router.push({ name: 'dealer-client-onboarding' });
            }

            this.$router.push({
                name: 'dealer-onboarding',
                params: {
                    dealer_id: this.activeDeployment.dealer_id,
                    onboarding_id: this.activeDeployment.id
                }
            });
        }
    }

};
</script>

<style lang="scss">
.interface-actions {
    .action-button {
        margin: 0 10px;
    }
}
</style>

<style lang="scss" scoped>
.comparison-dates {
    margin-left: 20px;
    margin-top: 5px;
    .title {
        display: block;
        color: $gray;
        text-transform: uppercase;
        font-size: 12px !important;
        font-weight: 400;
        font-family: 'Open Sans', sans-serif;
        letter-spacing: normal !important;
    }
    .date-range {
        padding: 10px 0;
        font-size: 16px;
    }
}
.layout {
    position: relative;
    #metric-funnel {
        position: relative;
        z-index: 2;
    }
}
.dealer-status {
    margin: 15px 30px 0;
    width: 100%;
    @media (min-width: $bp-sm) {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .item {
        margin: 0 0 20px 0;
        @media (min-width: $bp-sm) {
            margin: 0 50px 0 0;
        }
    }
    .actions {
        margin-left: auto;
        margin-right: 0;
        .action-button {
            margin-right: 10px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .label {
        font-size: 13px;
    }
    .value {
        font-size: 16px;
        color: #00a2ea;
        margin-top: -18px;
    }
}
</style>
