<template>
    <div
        class="offline-events"
        :class="{
            error: !offlineEventsCurrent
        }">
        <!--
            This will help to simplify the different cases that can occur within this component
            CASE 1:
            Offline events are present and cover the date range specified
            CASE 2:
            Offline events are present but are not uploaded up until the end date selected
        -->
        <loader v-if="loading" />
        <template v-else>
            <!-- CASE 1 -->
            <template v-if="offlineEventsExist && offlineEventsCurrent">
                <div class="info-icon">
                    <!-- eslint-disable-next-line -->
                    <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="m10 0c5.5228475 0 10 4.4771525 10 10s-4.4771525 10-10 10-10-4.4771525-10-10 4.4771525-10 10-10zm1.6458333 16.9166667h-3.49999997v-8.75000003h3.49999997zm-3.49999997-12.25791859c0-.60181296.166665-1.04260795.5-1.32239819.333335-.27979023.7499975-.41968325 1.25-.41968325.47619287 0 .88690307.14253251 1.23214287.42760181s.5178571.72322479.5178571 1.31447963c0 .59125485-.1726173 1.03204983-.5178571 1.32239819s-.75595.43552037-1.23214287.43552037c-.5000025 0-.916665-.14517201-1.25-.43552037s-.5-.73114334-.5-1.32239819z" fill="#fff" fill-rule="evenodd"/></svg>
                </div>
                <div class="total-matched">
                    <span class="value">{{ totalOfflinePurchaseEvents }}</span>
                    <span class="description">total matched sales</span>
                </div>
                <div class="last-upload">
                    <span class="description">
                        Last event upload was on:
                        <router-link
                            :to="{
                                name: 'dealer-offline-events',
                                params: {
                                    dealer_id: currentDealer.id
                                }
                            }">
                            {{ lastEvent }}
                        </router-link>
                    </span>
                </div>
                <action-button
                    icon="indicator-add"
                    :to="{
                        name: 'dealer-offline-events',
                        params: {
                            dealer_id: currentDealer.id
                        }
                    }"
                    color="blue">
                    Upload Events
                </action-button>
            </template>

            <!-- CASE 2 -->
            <template v-if="!offlineEventsCurrent">
                <div class="info-icon">
                    <!-- eslint-disable-next-line -->
                    <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="m10 0c5.5228475 0 10 4.4771525 10 10s-4.4771525 10-10 10-10-4.4771525-10-10 4.4771525-10 10-10zm1.6458333 16.9166667h-3.49999997v-8.75000003h3.49999997zm-3.49999997-12.25791859c0-.60181296.166665-1.04260795.5-1.32239819.333335-.27979023.7499975-.41968325 1.25-.41968325.47619287 0 .88690307.14253251 1.23214287.42760181s.5178571.72322479.5178571 1.31447963c0 .59125485-.1726173 1.03204983-.5178571 1.32239819s-.75595.43552037-1.23214287.43552037c-.5000025 0-.916665-.14517201-1.25-.43552037s-.5-.73114334-.5-1.32239819z" fill="#fff" fill-rule="evenodd"/></svg>
                </div>
                <p>
                    Last uploaded Purchase event was on {{ lastEvent }}. Please
                    <router-link
                        :to="{
                            name: 'dealer-offline-events',
                            params: {
                                dealer_id: currentDealer.id
                            }
                        }">
                        <span>upload events</span>
                    </router-link> up to {{ endDate }} to view up to date sales attribution for this period.
                </p>
                <router-link
                    class="action-button"
                    :to="{
                        name: 'dealer-offline-events',
                        params: {
                            dealer_id: currentDealer.id
                        }
                    }">
                    Upload Events
                </router-link>
            </template>
        </template>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Loader from '@/components/globals/Loader';
import ActionButton from '@/components/globals/ActionButton';
import { TOGGLE_NEW_TICKET_DIALOG } from '@/store/mutation-types';
import moment from 'moment';
export default {
    name: 'OfflineEvents',
    components: {
        ActionButton,
        Loader
    },
    computed: {
        ...mapState({
            currentDealer: state => state.dealer.currentDealer,
            dateRange: state => state.metrics.dateRange
        }),
        ...mapState('metrics/facebook/ads', {
            offlineEvents: (state) => state.offlineEvents,
            offlineEventsLoading: (state) => state.offlineEventsLoading,
            offlineEventsCurrent: (state) => state.offlineEventsCurrent,
            offlineEventsCurrentLoading: (state) => state.offlineEventsCurrentLoading,
        }),
        ...mapGetters('metrics/facebook/ads', [
            'offlinePurchaseEvents',
            'totalOfflinePurchaseEvents',
            'lastOfflinePurchaseEventDate',
            'canViewOfflinePurchaseAttribution'
        ]),
        loading() {
            return this.offlineEventsLoading || this.offlineEventsCurrentLoading;
        },
        lastEvent() {
            return moment(this.lastOfflinePurchaseEventDate).format('M/D/Y');
        },
        endDate() {
            return moment(this.dateRange.endDate).format('M/D/Y');
        },
        offlineEventsExist() {
            return this.offlinePurchaseEvents.length > 0;
        },
        offlineEventsCurrent() {
            return moment(this.endDate) <= moment(this.lastEvent);
        }
    },
    methods: {
        openSupportTicket() {
            this.$store.commit(TOGGLE_NEW_TICKET_DIALOG, true);
        }
    }
};
</script>

<style lang="scss" scoped>
.offline-events {
    position: relative;
    height: 85px;
    padding: 0 45px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    &.warning {
        color: $white;
        background: #dd8900 !important;
        .action-button {
            color: #dd8900
        }
    }
    &.error {
        color: $white;
        background: #ee5643 !important;
        .action-button {
            color: #ee5643
        }
    }
}
p {
    margin: 0;
    a {
        color: $white;
    }
}
.info-icon {
    position: absolute;
    top: 10px;
    right: 10px;
}
.action-button {
    display: block;
    appearance: none;
    padding: 2px 10px;
    text-transform: uppercase;
    font-weight: 600;
    background: white;
    border-radius: 5px;
    white-space: nowrap;
    margin-left: 200px;
    text-decoration: none;
    &:hover {
        opacity: 0.8;
    }

}
.total-matched {
    .value {
        color: #00a2ea;
        font-weight: 800;
        margin-right: 2px;
    }
}

</style>