<template>
    <div class="chart-container">
        <div class="top-panel">
            <styled-selector
                v-if="availableMetrics.length"
                class="metric-selector"
                label="Metric"
                :items="selectorMetrics"
                :value="metric.id"
                @input="selectMetric" />
            <div class="primary-total">
                <p class="metric">
                    {{ metric.name }}
                </p>
                <template v-if="metric.id == 'leads'">
                    <styled-tooltip
                        :max-width="500"
                        position="bottom">
                        <template #content>
                            <div class="font-weight-bold">
                                Leads
                            </div>
                            <div class="ml-2 mt-2 font-weight-bold">
                                On-Facebook Leads: {{ metricTotals.on }}
                            </div>
                            <div class="ml-4">
                                Facebook Conversions: {{ metricTotals.actions_onsite_conversion_lead_grouped }}
                            </div>
                            <div class="ml-4">
                                Facebook Messenger Conversations Started: {{ metricTotals.messaging_conversation_started_7d }}
                            </div>
                            <div class="ml-4">
                                Facebook Calls Placed: {{ metricTotals.actions_click_to_call_native_call_placed }}
                            </div>
                            <div class="ml-2 mt-2 font-weight-bold">
                                Off-Facebook Leads: {{ metricTotals.off }}
                            </div>
                            <div class="ml-4">
                                Facebook Pixel Leads: {{ metricTotals.website_leads }}
                            </div>
                            <div class="ml-2 mt-3 font-weight-bold">
                                Total Leads: {{ (metricTotals && metricTotals.primary) ? metricTotals.primary.toLocaleString() : '--' }}
                            </div>
                        </template>
                        <p class="value relative">
                            {{ (metricTotals && metricTotals.primary) ? metricTotals.primary.toLocaleString() : '--' }}
                            <icon
                                class="icon-top"
                                name="info"
                                color="#8f9ea6"
                                size="12" />
                        </p>
                    </styled-tooltip>
                </template>
                <template v-else>
                    <p class="value">
                        {{ (metricTotals && metricTotals.primary) ? metricTotals.primary.toLocaleString() : '--' }}
                    </p>
                </template>
            </div>
            <div
                v-if="metricTotals"
                class="secondary-totals">
                <div
                    v-if="metricTotals.cost && !hideSpend"
                    class="total">
                    <p class="metric">
                        {{ metricTotals.cost.name }}
                    </p>
                    <p class="value">
                        {{ metricTotals.cost.value }}
                    </p>
                </div>
            </div>
        </div>
        <div class="chart">
            <apex-chart
                type="area"
                :height="200"
                :options="options"
                :series="series" />
        </div>
        <div class="bottom-panel">
            <div class="stat previous">
                <p class="name">
                    Previous Period
                </p>
                <p class="date-range">
                    {{ formatDate(dateRangeComparison.startDate) }} to {{ formatDate(dateRangeComparison.endDate) }}
                </p>
                <p class="value">
                    {{ previousPeriodTotal.metric ? previousPeriodTotal.metric.toLocaleString() : previousPeriodTotal.metric }}
                </p>
                <template v-if="metric.cost && !hideSpend">
                    <p class="secondary-stat name">
                        {{ metric.cost ? metric.cost.name : '--' }}
                    </p>

                    <p class="value">
                        ${{ previousPeriodTotal.cost ? previousPeriodTotal.cost.toLocaleString(undefined, { minimumFractionDigits:2, maximumFractionDigits: 2 }) : previousPeriodTotal.cost }}
                    </p>
                </template>
            </div>
            <div class="stat last-month">
                <p class="name">
                    Last Month
                </p>
                <p class="date-range">
                    {{ formatDate(dateRangePreviousMonth.startDate) }} to {{ formatDate(dateRangePreviousMonth.endDate) }}
                </p>
                <p class="value">
                    {{ previousMonthTotal.metric ? previousMonthTotal.metric.toLocaleString() : previousMonthTotal.metric }}
                </p>
                <template v-if="metric.cost && !hideSpend">
                    <p class="secondary-stat name">
                        {{ metric.cost ? metric.cost.name : '--' }}
                    </p>

                    <p class="value">
                        ${{ previousMonthTotal.cost ? previousMonthTotal.cost.toLocaleString(undefined, { minimumFractionDigits:2, maximumFractionDigits: 2 }) : previousMonthTotal.cost }}
                    </p>
                </template>
            </div>
            <div class="stat average">
                <p class="name">
                    90 Day Average
                </p>
                <p class="date-range">
                    {{ formatDate(dateRangePrevious90Days.startDate) }} to {{ formatDate(dateRangePrevious90Days.endDate) }}
                </p>
                <p class="value">
                    {{ average90DayTotal.metric ? average90DayTotal.metric.toLocaleString() : average90DayTotal.metric }}
                </p>
                <p class="interval">
                    /MO
                </p>
                <template v-if="metric.cost && !hideSpend">
                    <p class="secondary-stat name">
                        {{ metric.cost ? metric.cost.name : '--' }}
                    </p>

                    <p class="value">
                        ${{ average90DayTotal.cost ? average90DayTotal.cost.toLocaleString(undefined, { minimumFractionDigits:2, maximumFractionDigits: 2 }) : average90DayTotal.cost }}
                    </p>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import StyledSelector from '@/components/globals/StyledSelector';
import StyledTooltip from '@/components/globals/StyledTooltip';
import Icon from '@/components/globals/Icon';
import ApexChart from 'vue-apexcharts';
import moment from 'moment';

export default {
    name: 'Chart',
    components: {
        StyledSelector,
        StyledTooltip,
        Icon,
        ApexChart
    },
    props: {
        metric: {
            type: Object,
            default: () => {}
        },
        availableMetrics: {
            type: Array,
            default: () => []
        },
        insights: {
            type: Object,
            default: () => {}
        },
        metricSeries: {
            type: Array,
            default: () => []
        },
        showSales: {
            type: Boolean,
            default: true
        },
        updated: {
            type: String,
            default: () => {return moment().format('M/D/Y @ h:mma')}
        }
    },
    data() {
        return {
            options: {
                grid: {
                    show: false
                },
                legend: {
                    show: false
                },
                colors: ['#00A2EA', '#E350B4'],
                fill: {
                    type: ['gradient', 'solid'],
                    colors: ['#00CFF6']
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: 2,
                    curve: 'straight',
                    dashArray: [0, 5]
                },
                chart: {
                    height: 200,
                    toolbar: {
                        show: false
                    }
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    }
                },
                yaxis: [
                    {
                        show: false
                    },
                    {
                        show: false,
                        opposite: true
                    }
                ],
                tooltip: {
                    y: [
                        {
                            formatter(value) {
                                return value.toLocaleString();
                            }
                        },
                        {
                            formatter(value) {
                                return '$' + value.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                });
                            }
                        }
                    ]
                }
            },
            series: []
        };
    },
    computed: {
        ...mapState({
            dateRange: (state) => state.metrics.dateRange,
            dateRangeComparison: (state) => state.metrics.dateRangeComparison
        }),
        ...mapGetters([
            'userIsClient'
        ]),
        ...mapGetters('metrics', [
            'dateRangePreviousMonth',
            'dateRangePrevious90Days',
            'hideSpend'
        ]),
        loading() {
            return !this.insights.selected.hasOwnProperty(this.metric.value);
        },
        metricTotals() {
            if (!this.loading && this.metric.cost) {
                return {
                    primary: this.insights.selected[this.metric.value].value,
                    cost: {
                        name: this.metric.cost.name,
                        value: this.insights.selected[this.metric.cost.key].formatted
                    },
                    off: this.insights?.selected?.website_leads?.formatted,
                    actions_onsite_conversion_lead_grouped: this.insights?.selected?.actions_onsite_conversion_lead_grouped?.formatted,
                    messaging_conversation_started_7d: this.insights?.selected?.messaging_conversation_started_7d?.formatted,
                    actions_click_to_call_native_call_placed: this.insights?.selected?.actions_click_to_call_native_call_placed?.formatted,
                    on: this.insights?.selected?.on_site_leads?.value,
                    website_leads: this.insights?.selected?.website_leads?.formatted
                };
            } else if(!this.metric.cost) {
                return {
                    primary: this.insights.selected[this.metric.value]?.value,

                };
            } else {
                return false;
            }
        },
        selectorMetrics() {
            let metrics =  this.availableMetrics
                .map(m => {
                return {
                    name: m.name,
                    value: m.id
                };
            });
            if(!this.showSales) {
                metrics.splice(-1);
            }
            return metrics;
        },
        previousPeriodTotal() {
            if (!this.loading && this.insights.previousPeriod && this.metric.cost) {
                return {
                    metric: this.insights.previousPeriod[this.metric.value].value,
                    cost: this.insights.previousPeriod[this.metric.cost.key].value
                };
            } else if (!this.metric.cost) {
                if (this.insights.previousPeriod) {
                    return {
                        metric: this.insights.previousPeriod[this.metric.value]?.value
                    };
                } else {
                    return '--';
                }
            } else {
                return '--';
            }
        },
        previousPeriodChange() {
            if (!this.loading) {
                if(this.previousPeriodTotal.metric === 0 || this.previousPeriodTotal.metric === '--') {
                    return '--';
                }
                return (
                    ((this.metricTotals.primary - this.previousPeriodTotal.metric) / this.previousPeriodTotal.metric) *100).toFixed(1) + '%';
            }
            return '--';
        },
        previousMonthTotal() {
            if (!this.loading && this.insights.previousMonth && this.metric.cost) {
                return {
                    metric: this.insights.previousMonth[this.metric.value].value,
                    cost: this.insights.previousMonth[this.metric.cost.key].value
                };
            } else if (!this.metric.cost) {
                if (this.insights.previousMonth) {
                    return {
                        metric: this.insights.previousMonth[this.metric.value]?.value
                    };
                } else {
                    return '--';
                }
            } else {
                return '--';
            }
        },
        average90DayTotal() {
            if (!this.loading && this.insights.average90Day && this.metric.cost) {
                return {
                    metric: Math.round(this.insights.average90Day[this.metric.value].value / 3),
                    cost: this.metric.cost.key == 'spend' ? this.insights.average90Day[this.metric.cost.key].value / 3 : this.insights.average90Day[this.metric.cost.key].value
                };
            } else if(!this.metric.cost) {
                return {
                    metric: Math.round(this.insights.average90Day[this.metric.value]?.value / 3)
                };
            } else {
                return '--';
            }
        }
    },
    watch: {
        metric() {
            this.setSeries();
        },
        hideSpend() {
            this.setSeries();
        }
    },
    methods: {
        setSeries() {
            this.series = [];
            this.series.push(this.getPrimarySeries());
            const secondary = this.getSecondarySeries();
            if(secondary && !this.hideSpend) {
                this.series.push(secondary);
            }
        },
        formatDate(date) {
            if (date instanceof Date) {
                return moment(date).format('M/D/YY');
            }
            return moment(date.slice(0, -1)).format('M/D/YY');
        },
        selectMetric(selected) {
            let metric = this.availableMetrics.find(m => m.id == selected.value);
            this.$emit('changeMetric', metric);
        },

        getPrimarySeries() {
            return {
                name: this.metric.name,
                type: 'area',
                data: this.metricSeries.map(row => {
                    return [
                        row.date ? row.date.value : row.date_start.value,
                        row[this.metric.value].value
                    ];
                })
            };

        },
        getSecondarySeries() {
            const cost = this.metric.cost;

            if(cost) {
                return {
                    name: cost.name,
                    type: 'line',
                    data: this.metricSeries.map(row => {
                        return [
                            row.date ? row.date.value : row.date_start.value,
                            row[cost.key].value || 0
                        ];
                    })
                };
            } else {
                return false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.chart-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.metric-selector {
    padding: 30px;
}
.top-panel {
    position: relative;
    height: 250px;
    p {
        margin-bottom: 0;
    }
    .primary-total {
        text-align: center;
        position: absolute;
        top: 120px;
        left: 50%;
        transform: translateX(-50%);
        .metric {
            font-size: 12px;
            text-transform: uppercase;
        }

        .value {
            font-size: 36px;
            font-weight: 600;
            color: #00a2ea;
        }
    }
    .secondary-totals {
        display: flex;
        justify-content: center;
        width: 300px;
        flex-wrap: wrap;
        position: absolute;
        top: 200px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        .total {
            width: 50%;
        }
        .metric {
            font-size: 10px;
            text-transform: uppercase;
        }

        .value {
            font-size: 18px;
            font-weight: 600;
            color: #00a2ea;
        }
    }
}
.chart {
    margin: 0 -12px -30px -12px;

}
.bottom-panel {
    flex: 1;
    display: flex;
    flex-direction: row;
    .stat {
        padding: 15px;
        padding-top: 30px;
        justify-content: center;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-right: 1px solid #d8d8d8;

        &:last-of-type {
            border: none;
        }
        p {
            margin-bottom: 0;
        }

        .name {
            text-transform: uppercase;
            font-size: 14px;
        }

        .date-range {
            font-size: 13px;
            color: #787878;
        }
        .value {
            font-size: 28px;
            font-weight: 600;
            color: #00a2ea;
        }
        .interval {
            font-size: 14px;
            font-weight: 400;
            color: #5c5c5c;
        }
        .secondary-stat {
            margin-top: 15px;
            font-size: 13px;
            color: #787878;
        }
    }
}

.icon-top {
    margin-left: 5px;
    top: 0px;
    cursor: pointer;
    position: absolute;
}

.relative {
    position: relative;
}
</style>
