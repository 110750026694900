<template>
    <styled-card
        id="faceboook-ad-stats"
        class="flex ma-3 xs12"
        hide-toggle>
        <template #heading>
            Meta Ad Stats
        </template>
        <template #action-buttons>
            <action-button
                :to="{name: 'reports-facebook-advertising'}"
                icon="report-detailed">
                See Details
            </action-button>
        </template>
        <loader
            v-if="loadingAdInsights"
            class="mb-5" />
        <div
            v-else-if="dataAvailable"
            class="stats">
            <div class="flex lg2 md6 xs12">
                <market-stats
                    title="CPM"
                    :metric="insightsTotals.cpm.formatted"
                    :metric-value="insightsTotals.cpm.value"
                    :previous-metric-value="showChange ? insightsTotalsComparison.cpm.value : 0"
                    :tool-tip-message="$t('metrics.cpm')"
                    :show-change="showChange"
                    change-reversed />
            </div>
            <div class="flex lg2 md6 xs12">
                <market-stats
                    title="CPC"
                    :metric="insightsTotals.cost_per_click.formatted"
                    :metric-value="insightsTotals.cost_per_click.value"
                    :previous-metric-value="showChange ? insightsTotalsComparison.cost_per_click.value : 0"
                    :tool-tip-message="$t('metrics.cpc')"
                    :show-change="showChange"
                    change-reversed />
            </div>
            <div class="flex lg2 md6 xs12">
                <market-stats
                    title="CPOC"
                    :metric="insightsTotals.cost_per_outbound_click.formatted"
                    :metric-value="insightsTotals.cost_per_outbound_click.value"
                    :previous-metric-value="showChange ? insightsTotalsComparison.cost_per_outbound_click.value : 0"
                    :tool-tip-message="$t('metrics.cpoc')"
                    :show-change="showChange"
                    change-reversed />
            </div>
            <div class="flex lg2 md6 xs12">
                <market-stats
                    title="CPLPV"
                    :metric="insightsTotals.cost_per_landing_page_view.formatted"
                    :metric-value="insightsTotals.cost_per_landing_page_view.value"
                    :previous-metric-value="showChange ? insightsTotalsComparison.cost_per_landing_page_view.value : 0"
                    :tool-tip-message="$t('metrics.cplpv')"
                    :show-change="showChange"
                    change-reversed />
            </div>
            <div class="flex lg2 md6 xs12">
                <market-stats
                    title="CPL"
                    :metric="insightsTotals.cost_per_lead.formatted"
                    :metric-value="insightsTotals.cost_per_lead.value"
                    :previous-metric-value="showChange ? insightsTotalsComparison.cost_per_lead.value : 0"
                    :tool-tip-message="$t('metrics.cpl')"
                    :show-change="showChange"
                    change-reversed />
            </div>
            <div
                v-if="canViewOfflinePurchaseAttribution"
                class="flex lg2 md6 xs12">
                <market-stats
                    title="CPS"
                    :metric="insightsTotals.cost_per_offline_purchase.formatted"
                    :metric-value="insightsTotals.cost_per_offline_purchase.value"
                    :previous-metric-value="showChange ? insightsTotalsComparison.cost_per_offline_purchase.value : 0"
                    :tool-tip-message="$t('metrics.cost_per_offline_purchase')"
                    :show-change="showChange"
                    change-reversed />
            </div>
        </div>
        <div
            v-else
            class="no-data">
            <p>No Data Available for the selected dealer &amp; date range</p>
        </div>
    </styled-card>
</template>

<script>
import { mapGetters } from 'vuex';
import Loader from '@/components/globals/Loader';
import StyledCard from '@/components/globals/StyledCard';
import ActionButton from '@/components/globals/ActionButton';
import MarketStats from '@/components/globals/MarketStats';

export default {
    name: 'FacebookAdStats',
    components: {
        StyledCard,
        ActionButton,
        Loader,
        MarketStats
    },
    computed: {
        ...mapGetters([
            'dealerHasFeatures',
            'userIsClient'
        ]),
        ...mapGetters('metrics/facebook/ads', {
            insightsTotals: 'metricsTotalsFormatted',
            insightsTotalsComparison: 'metricsTotalsComparisonFormatted',
            loadingAdInsights: 'metricsLoading',
            hasMetrics: 'hasMetrics',
            canViewOfflinePurchaseAttribution: 'canViewOfflinePurchaseAttribution',
        }),
        dataAvailable() {
            return this.hasMetrics && this.dealerHasFeatures(['facebook_ad_account']);
        },
        showChange() {
            return !this.userIsClient && !!this.insightsTotalsComparison;
        }
    }
};
</script>

<style lang="scss" scoped>
.stats {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}
.no-data {
    padding: 30px;
    text-align: center;
    font-style: italic;
}
</style>
