<template>
    <div class="funnel-container">
        <styled-selector
            label="Types"
            :items="availableFunnels"
            item-text="name"
            item-value="value"
            @input="changeFunnel" />
        <div class="svg-container">
            <svg viewBox="0 0 532 465">
                <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd">
                    <g transform="translate(-117, -1095)">
                        <g transform="translate(59, 939)">
                            <g transform="translate(58, 156)">
                                <!-- Right Side Outlines -->
                                <path
                                    v-if="!hideSpend"
                                    d="M530.5,0.5 L530.5,74.5 L351.5,74.5 L351.5,0.5 L530.5,0.5 Z"
                                    stroke="#00A2EA" />
                                <path
                                    v-if="!hideSpend"
                                    d="M497.5,130.5 L497.5,204.5 L318.5,204.5 L318.5,130.5 L497.5,130.5 Z"
                                    stroke="#00A2EA" />
                                <path
                                    v-if="!hideSpend"
                                    d="M464.5,259.5 L464.5,333.5 L285.5,333.5 L285.5,259.5 L464.5,259.5 Z"
                                    stroke="#00A2EA" />
                                <path
                                    v-if="!hideSpend"
                                    d="M497.585561,205 L464.5,260 L497.585561,205 Z"
                                    stroke="#00A2EA" />
                                <path
                                    v-if="!hideSpend"
                                    d="M530.585561,75 L497.5,130 L530.585561,75 Z"
                                    stroke="#00A2EA" />


                                <!-- METRIC 1 -->
                                <g
                                    class="step"
                                    :class="{'active-step': metric == availableMetrics[0]}"
                                    @click="selectMetric(availableMetrics[0])">
                                    <polygon
                                        class="step-bg"
                                        points="33 0 412 0 412 75 33 75" />
                                    <g class="step-text">
                                        <g
                                            transform="translate(141, 20)"
                                            font-family="OpenSans-Semibold, Open Sans">
                                            <text
                                                x="85"
                                                y="25"
                                                text-anchor="end">
                                                <tspan
                                                    font-size="14"
                                                    font-weight="500">{{ activeFunnel.metrics[0].title | uppercase }}</tspan>
                                            </text>
                                            <text
                                                v-if="!!activeFunnel.metrics[0].subtitle"
                                                x="85"
                                                y="34"
                                                text-anchor="end">
                                                <tspan font-size="13">({{ activeFunnel.metrics[0].subtitle }})</tspan>
                                            </text>
                                            <text
                                                x="100"
                                                y="30"
                                                text-anchor="start">
                                                <tspan font-size="32">{{ getFormattedMetric(activeFunnel.metrics[0].metric) }}</tspan>
                                            </text>
                                        </g>
                                    </g>
                                </g>


                                <!-- METRIC 1 COST -->
                                <g
                                    v-if="!hideSpend"
                                    transform="translate(432, 19)"
                                    font-family="OpenSans-Regular, Open Sans">
                                    <text
                                        x="40"
                                        y="12"
                                        fill="#4A4A4A"
                                        text-anchor="middle">
                                        <tspan
                                            font-size="11"
                                            font-weight="normal">{{ activeFunnel.metrics[0].cost.title | uppercase }}</tspan>
                                    </text>
                                    <text
                                        text-anchor="middle"
                                        x="38"
                                        y="37"
                                        fill="#00A2EA">
                                        <tspan
                                            font-size="21"
                                            font-weight="500">{{ getFormattedMetric(activeFunnel.metrics[0].cost.metric) }}</tspan>
                                    </text>
                                </g>


                                <!-- METRIC 1 RATE -->
                                <polygon
                                    fill="#038AC6"
                                    points="33 75 412.066349 75 378.980788 130 65.4660968 130" />
                                <g
                                    font-family="OpenSans-Semibold, Open Sans"
                                    font-size="20"
                                    font-weight="500"
                                    fill="#FFFFFF">
                                    <text
                                        x="225"
                                        y="105"
                                        opacity="0.5"
                                        text-anchor="end">
                                        <tspan font-size="12">{{ activeFunnel.metrics[0].rate.title | uppercase }}</tspan>
                                    </text>
                                    <text
                                        x="245"
                                        y="110"
                                        opacity="0.5"
                                        text-anchor="start">
                                        <tspan>{{ getFormattedMetric(activeFunnel.metrics[0].rate.metric) }}</tspan>
                                    </text>
                                </g>


                                <!-- METRIC 2 -->
                                <g
                                    class="step"
                                    :class="{'active-step': metric == availableMetrics[1]}"
                                    @click="selectMetric(availableMetrics[1])">
                                    <g
                                        class="step-bg"
                                        transform="translate(66, 130)">
                                        <polygon points="0 0 313 0 313 75 0 75" />
                                    </g>
                                    <g
                                        class="step-text"
                                        font-family="OpenSans-Semibold, Open Sans"
                                        font-weight="500">
                                        <g transform="translate(88, 149)">
                                            <text
                                                x="140"
                                                y="25"
                                                text-anchor="end">
                                                <tspan
                                                    :font-size="activeFunnel.metrics[1].title_font_size || '14'">
                                                    {{ activeFunnel.metrics[1].title | uppercase }}
                                                </tspan>
                                            </text>
                                            <text
                                                v-if="!!activeFunnel.metrics[1].subtitle"
                                                x="140"
                                                y="34"
                                                text-anchor="end">
                                                <tspan font-size="11">({{ activeFunnel.metrics[1].subtitle }})</tspan>
                                            </text>
                                        </g>
                                        <text
                                            x="240"
                                            y="180"
                                            text-anchor="start">
                                            <tspan font-size="32">{{ getFormattedMetric(activeFunnel.metrics[1].metric) }}</tspan>
                                        </text>
                                    </g>
                                </g>


                                <!-- METRIC 2 COST -->
                                <g
                                    v-if="!hideSpend"
                                    transform="translate(373, 142)"
                                    font-family="OpenSans-Regular, Open Sans">
                                    <text
                                        x="65"
                                        y="15"
                                        text-anchor="middle"
                                        font-weight="normal"
                                        fill="#4A4A4A">
                                        <tspan font-size="11">{{ activeFunnel.metrics[1].cost.title | uppercase }}</tspan>
                                    </text>
                                    <text
                                        x="62"
                                        y="40"
                                        text-anchor="middle"
                                        font-size="21"
                                        font-weight="500"
                                        fill="#00A2EA">
                                        <tspan>{{ getFormattedMetric(activeFunnel.metrics[1].cost.metric) }}</tspan>
                                    </text>
                                </g>


                                <!-- METRIC 2 RATE -->
                                <polygon
                                    fill="#038AC6"
                                    points="66 205 379.551658 205 346.466097 260 98.4660968 260" />
                                <text
                                    opacity="0.5"
                                    font-family="OpenSans-Semibold, Open Sans"
                                    font-size="12"
                                    font-weight="500"
                                    fill="#FFFFFF">
                                    <tspan
                                        x="227"
                                        y="229"
                                        text-anchor="end">CONVERSION</tspan>
                                    <tspan
                                        x="227"
                                        y="246"
                                        text-anchor="end">RATE</tspan>
                                </text>
                                <text
                                    opacity="0.5"
                                    font-family="OpenSans-Semibold, Open Sans"
                                    font-size="20"
                                    font-weight="500"
                                    fill="#FFFFFF">
                                    <tspan
                                        x="245"
                                        y="240"
                                        text-anchor="start">{{ getFormattedMetric(activeFunnel.metrics[1].rate.metric) }}</tspan>
                                </text>


                                <!-- LEADS -->
                                <g
                                    class="step"
                                    :class="{'active-step': metric ==availableMetrics[2]}"
                                    @click="selectMetric(availableMetrics[2])">
                                    <polygon
                                        class="step-bg"
                                        points="99 260 346 260 346 335 99 335" />
                                    <g
                                        class="step-text"
                                        font-family="OpenSans-Semibold, Open Sans"
                                        font-weight="500">
                                        <text
                                            x="227"
                                            y="302"
                                            text-anchor="end"
                                            font-size="14">
                                            <tspan>LEADS</tspan>
                                        </text>
                                        <text
                                            x="240"
                                            y="309"
                                            text-anchor="start"
                                            font-size="32">
                                            <tspan>{{ getFormattedMetric(activeFunnel.metrics[2].metric) }}</tspan>
                                        </text>
                                    </g>
                                </g>


                                <!-- COST PER LEAD -->
                                <g
                                    v-if="!hideSpend"
                                    transform="translate(359, 275)">
                                    <text
                                        x="45"
                                        y="12"
                                        text-anchor="middle"
                                        font-family="OpenSans-Regular, Open Sans"
                                        fill="#4A4A4A">
                                        <tspan
                                            font-size="11"
                                            font-weight="normal">COST / LEAD</tspan>
                                    </text>
                                    <text
                                        x="45"
                                        y="37"
                                        text-anchor="middle"
                                        font-family="OpenSans-Semibold, Open Sans"
                                        fill="#00A2EA">
                                        <tspan
                                            font-size="21"
                                            font-weight="500">{{ getFormattedMetric(activeFunnel.metrics[2].cost.metric) }}</tspan>
                                    </text>
                                </g>
                                <template v-if="hasOfflinePurchaseEvents">
                                    <path
                                        v-if="!hideSpend"
                                        d="M431.5,390.5 L431.5,464.5 L252.5,464.5 L252.5,390.5 L431.5,390.5 Z"
                                        stroke="#00A2EA" />
                                    <path
                                        v-if="!hideSpend"
                                        d="M463.585561,334 L430.5,389 L463.585561,334 Z"
                                        stroke="#00A2EA" />


                                    <!-- LEAD CONVERSION RATE -->
                                    <polygon
                                        fill="#038AC6"
                                        points="99 335 346.051658 335 312.966097 390 131.466097 390" />
                                    <text
                                        opacity="0.5"
                                        font-family="OpenSans-Semibold, Open Sans"
                                        font-size="12"
                                        font-weight="500"
                                        fill="#FFFFFF">
                                        <tspan
                                            x="227"
                                            y="359"
                                            text-anchor="end">CONVERSION</tspan>
                                        <tspan
                                            x="227"
                                            y="376"
                                            text-anchor="end">RATE</tspan>
                                    </text>
                                    <text
                                        opacity="0.5"
                                        font-family="OpenSans-Semibold, Open Sans"
                                        font-size="20"
                                        font-weight="500"
                                        fill="#FFFFFF">
                                        <tspan
                                            x="245"
                                            y="370"
                                            text-anchor="start">{{ getFormattedMetric(activeFunnel.metrics[2].rate.metric) }}</tspan>
                                    </text>


                                    <!-- SALES -->
                                    <g
                                        class="step"
                                        :class="{'active-step': metric == availableMetrics[3]}"
                                        @click="selectMetric(availableMetrics[3])">
                                        <polygon
                                            class="step-bg"
                                            points="132 390 312 390 312 465 132 465" />
                                        <g class="step-text">
                                            <text
                                                font-family="OpenSans-Semibold, Open Sans"
                                                font-size="12"
                                                font-weight="500">
                                                <tspan
                                                    x="227"
                                                    y="432"
                                                    text-anchor="end">SALES</tspan>
                                            </text>
                                            <text
                                                font-family="OpenSans-Semibold, Open Sans"
                                                font-size="32"
                                                font-weight="500">
                                                <tspan
                                                    x="240"
                                                    y="440"
                                                    text-anchor="start">{{ getFormattedMetric(activeFunnel.metrics[3].metric) }}</tspan>
                                            </text>
                                        </g>
                                    </g>


                                    <!-- COST PER SALE -->
                                    <g
                                        v-if="!hideSpend"
                                        transform="translate(310, 406)">
                                        <text
                                            x="60"
                                            y="12"
                                            text-anchor="middle"
                                            font-family="OpenSans-Regular, Open Sans"
                                            fill="#4A4A4A">
                                            <tspan
                                                font-size="11"
                                                font-weight="normal">COST / SALE</tspan>
                                        </text>
                                        <text
                                            x="60"
                                            y="37"
                                            text-anchor="middle"
                                            font-family="OpenSans-Semibold, Open Sans"
                                            fill="#00A2EA">
                                            <tspan
                                                font-size="21"
                                                font-weight="500">{{ getFormattedMetric(activeFunnel.metrics[3].cost.metric) }}</tspan>
                                        </text>
                                    </g>
                                </template>
                                <template v-else-if="!hideSpend">
                                    <polygon
                                        fill="#00A2EA"
                                        fill-rule="nonzero"
                                        points="132 390 418 390 418 465 132 465" />
                                    <polygon
                                        fill="#038AC6"
                                        fill-rule="nonzero"
                                        points="99 335 464 335 419 390 131.466097 390" />
                                </template>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
        <styled-button
            v-if="!hasOfflinePurchaseEvents && !hideSpend"
            id="upload-events-button"
            red
            :to="{
                name: 'dealer-offline-events',
                params: {
                    dealer_id: currentDealerId
                }
            }">
            UPLOAD EVENTS
        </styled-button>
        <div
            class="step-selection-indicator"
            :class="metric.id">
            <svg
                width="28px"
                height="48px"
                viewBox="0 0 28 48">
                <g
                    stroke="none"
                    stroke-width="1"
                    fill-rule="evenodd">
                    <g
                        transform="translate(-664, -803)"
                        fill="#ADF0FC">
                        <polygon
                            transform="translate(666.258622, 826.686292) rotate(-225) translate(-666.258622, -826.686292) "
                            points="648.258622 808.686292 684.258622 811.31242 650.88475 844.686292" />
                    </g>
                </g>
            </svg>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import StyledSelector from '@/components/globals/StyledSelector';
import StyledButton from '@/components/globals/StyledButton';

export default {
    name: 'AdvertisingFunnel',
    components: {
        StyledSelector,
        StyledButton
    },

    props: {
        adInsights: {
            type: Object,
            default: ()=> {
                return {};
            }
        },
        metric: {
            type: Object,
            default: ()=> {
                return {};
            }
        }
    },
    data() {
        return {
            selectedFunnel: 'all',
            funnels: [
                {
                    name: 'All Traffic',
                    id: 'all',
                    metrics: [
                        {
                            id: 'prospects',
                            title: 'Impressions',
                            metric: 'impressions',
                            cost: {
                                title: 'Spend',
                                metric: 'spend'
                            },
                            rate: {
                                title: 'Click Rate',
                                metric: 'ctr'
                            }
                        },
                        {
                            id: 'shoppers',
                            title: 'Clicks',
                            metric: 'clicks',
                            cost: {
                                title: 'Cost / Click',
                                metric: 'cost_per_click'
                            },
                            rate: {
                                title: 'Conversion Rate',
                                metric: 'lead_to_click_rate'
                            }
                        },
                        {
                            id: 'leads',
                            title: 'Leads',
                            subtitle: null,
                            metric: 'leads',
                            cost: {
                                title: 'Cost / Lead',
                                metric: 'cost_per_lead'
                            },
                            rate: {
                                title: 'Conversion Rate',
                                metric: 'offline_purchase_rate'
                            }
                        },
                        {
                            id: 'sales',
                            title: 'Sales',
                            metric: 'offline_purchase',
                            cost: {
                                title: 'Cost / Per',
                                metric: 'cost_per_offline_purchase'
                            }
                        }
                    ]
                },
                {
                    name: 'Website Traffic',
                    id: 'default',
                    metrics: [
                        {
                            id: 'prospects',
                            title: 'Prospects',
                            //subtitle: 'Reach',
                            metric: 'reach',
                            cost: {
                                title: 'Spend',
                                metric: 'spend'
                            },
                            rate: {
                                title: 'Landing Page View Rate',
                                metric: 'landing_page_view_rate'
                            }
                        },
                        {
                            id: 'shoppers',
                            title: 'Landing Page Views',
                            //subtitle: 'Landing Page Views',
                            metric: 'landing_page_views',
                            cost: {
                                title: 'Cost / View',
                                metric: 'cost_per_landing_page_view'
                            },
                            rate: {
                                title: 'Conversion Rate',
                                metric: 'lead_to_landing_page_view_rate'
                            }
                        },
                        {
                            id: 'leads',
                            title: 'Leads',
                            subtitle: null,
                            metric: 'leads',
                            cost: {
                                title: 'Cost / Lead',
                                metric: 'cost_per_lead'
                            },
                            rate: {
                                title: 'Conversion Rate',
                                metric: 'offline_purchase_rate'
                            }
                        },
                        {
                            id: 'sales',
                            title: 'Sales',
                            metric: 'offline_purchase',
                            cost: {
                                title: 'Cost / Sale',
                                metric: 'cost_per_offline_purchase'
                            }
                        }
                    ]
                },
                {
                    name: 'Unique Traffic',
                    id: 'unique',
                    metrics: [
                        {
                            id: 'prospects',
                            title: 'Prospects',
                            metric: 'reach',
                            cost: {
                                title: 'Spend',
                                metric: 'spend'
                            },
                            rate: {
                                title: 'Unique Click Rate',
                                metric: 'unique_click_rate'
                            }
                        },
                        {
                            id: 'shoppers',
                            title: 'Unique Clicks',
                            metric: 'unique_clicks',
                            cost: {
                                title: 'Cost / Click',
                                metric: 'cost_per_unique_click'
                            },
                            rate: {
                                title: 'Conversion Rate',
                                metric: 'lead_to_unique_click_rate'
                            }
                        },
                        {
                            id: 'leads',
                            title: 'Leads',
                            subtitle: null,
                            metric: 'leads',
                            cost: {
                                title: 'Cost / Lead',
                                metric: 'cost_per_lead'
                            },
                            rate: {
                                title: 'Conversion Rate',
                                metric: 'offline_purchase_rate'
                            }
                        },
                        {
                            id: 'sales',
                            title: 'Sales',
                            metric: 'offline_purchase',
                            cost: {
                                title: 'Cost / Sale',
                                metric: 'cost_per_offline_purchase'
                            }
                        }
                    ]
                },
                {
                    name: 'On-Facebook Listings',
                    id: 'onfacebook',
                    metrics: [
                        {
                            id: 'prospects',
                            title: 'Prospects',
                            //subtitle: 'Reach',
                            metric: 'reach',
                            cost: {
                                title: 'Spend',
                                metric: 'spend'
                            },
                            rate: {
                                title: 'Unique Click Rate',
                                metric: 'unique_click_rate'
                            }
                        },
                        {
                            id: 'shoppers',
                            title: 'On-FB Content Views',
                            title_font_size: 12,
                            //subtitle: 'On-Facebook Content Views',
                            metric: 'clicks',
                            cost: {
                                title: 'Cost / Unique',
                                metric: 'cost_per_unique_on_site_content_view'
                            },
                            rate: {
                                title: 'Conversion Rate',
                                metric:
                                    'lead_to_unique_on_site_view_content_rate'
                            }
                        },
                        {
                            id: 'leads',
                            title: 'Leads',
                            subtitle: null,
                            metric: 'leads',
                            cost: {
                                title: 'Cost / Lead',
                                metric: 'cost_per_lead'
                            },
                            rate: {
                                title: 'Conversion Rate',
                                metric: 'offline_purchase_rate'
                            }
                        },
                        {
                            id: 'sales',
                            title: 'Sales',
                            metric: 'offline_purchase',
                            cost: {
                                title: 'Cost / Per',
                                metric: 'cost_per_offline_purchase'
                            }
                        }
                    ]
                }
            ]
        };
    },
    computed: {
        ...mapGetters('metrics/facebook/ads', {
            insightsTotals: 'metricsTotalsFormatted',
            offlinePurchaseEvents: 'offlinePurchaseEvents'
        }),
        ...mapGetters({
            hideSpend: 'metrics/hideSpend'
        }),
        ...mapState({
            currentDealerId: state => state.dealer.currentDealerId,
        }),
        hasOfflinePurchaseEvents() {
            return this.offlinePurchaseEvents.filter(event => event.count > 0).length > 0;
        },
        activeFunnel() {
            return this.funnels.find(
                funnel => funnel.id == this.selectedFunnel
            );
        },
        availableFunnels() {
            return this.funnels.map(funnel => {
                return {
                    name: funnel.name,
                    value: funnel.id
                };
            });
        },
        availableMetrics() {
            return this.activeFunnel.metrics.map(m => {
                return {
                    id: m.id,
                    name: m.title,
                    value: m.metric,
                    cost: { name: m.cost.title, key: m.cost.metric }
                };
            });
        }
    },
    created() {
        this.$emit('updateMetrics', this.availableMetrics);
        this.$emit('changeMetric', this.availableMetrics[0]);
    },
    methods: {
        changeFunnel(type) {
            this.selectedFunnel = type.value;
            this.$emit('updateMetrics', this.availableMetrics);
            this.$emit('changeMetric', this.availableMetrics[0]);
        },
        selectMetric(metric) {
            this.$emit('changeMetric', metric);
        },
        getFormattedMetric(metric) {
            if (this.insightsTotals[metric].value === 0) {
                return '-';
            }
            return this.insightsTotals[metric].formatted;
        }
    }
};
</script>

<style lang="scss" scoped>
$breakpoint: 1330px;
.funnel-container {
    position: relative;
    padding: 30px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #d8d8d8;

    .svg-container {
        margin-top: 40px;
        align-self: center;
        height: auto;
        width: 100%;
        max-width: 532px;
    }
    .step-selection-indicator {
        position: absolute;
        right: 60px;
        transition: top 0.2s linear;
        display:none;
        @media (min-width: $breakpoint) {
            &.prospects {
                display: block;
                top: 100px;
            }
            &.shoppers {
                display: block;
                top: 230px;
            }
            &.leads {
                display: block;
                top: 365px;
            }
            &.sales {
                display: block;
                top: 495px;
            }
        }
    }
    .step {
        .step-bg {
            fill: #00a2ea;
        }
        .step-text {
            fill: #ffffff;
        }
        &.active-step,
        &:hover {
            cursor: pointer;
            .step-bg {
                fill: #adf0fc;
            }
            .step-text {
                fill: #00a2ea;
            }
        }
    }
    #upload-events-button {
        position: relative;
        top: -68px;
        left: 50%;
        transform: translateX(-50%);
        width: 200px;
        font-weight: 600;
    }
}
</style>