<template>
    <styled-slideout
        class=" mb-4 metric-info-slideout"
        :value="value"
        @input="$emit('input', $event)">
        <div class="pa-5">
            <h2>Facebook Funnel Types</h2>
            <p class="mb-3">
                In order to evaluate different types of campaigns &amp; ads we've created specific funnels catered
                to common needs. Read more about them below!
            </p>
            <p class="mb-1 grey--text">
                Global metrics:
            </p>
            <ul class="mb-4">
                <li class="caption mb-2">
                    <strong>{{ overallMetricDescriptions[0].metric }}</strong>: {{ overallMetricDescriptions[0].description }}
                </li>
                <li class="caption mb-2">
                    <strong>{{ overallMetricDescriptions[1].metric }}</strong>: {{ overallMetricDescriptions[1].description }}
                </li>
                <li class="caption mb-2">
                    <strong>{{ overallMetricDescriptions[2].metric }}</strong>: {{ overallMetricDescriptions[2].description }}
                </li>
                <li class="caption mb-2">
                    <strong>{{ overallMetricDescriptions[3].metric }}</strong>: {{ overallMetricDescriptions[3].description }}
                </li>
            </ul>
            <div>
                <h3>Default Funnel</h3>
                <p class="mb-3">
                    A traditional attribution overview focused on website traffic mid-funnel. This funnel is
                    suggested for ad campaigns that dominantly link out to a website as their primary call-to-action.
                </p>
                <p class="mb-1 grey--text">
                    Funnel-specific metrics:
                </p>
                <ul class="mb-4">
                    <li class="caption mb-2">
                        <strong>{{ defaultDescriptions[0].metric }}</strong>: {{ defaultDescriptions[0].description }}
                    </li>
                </ul>
            </div>
            <div>
                <h3>All Traffic Funnel</h3>
                <p class="mb-3">
                    A more modern attribution overview focused on unique-clicks to ensure all ad types are included
                    in the evaluation. Use this funnel if you are evaluating a wide array of campaigns and want to ensure all ad
                    types are being considered.
                </p>
                <p class="mb-1 grey--text">
                    Funnel-specific metrics:
                </p>
                <ul class="mb-4">
                    <li class="caption mb-2">
                        <strong>{{ allTrafficDescriptions[0].metric }}</strong>: {{ allTrafficDescriptions[0].description }}
                    </li>
                </ul>
            </div>
            <div>
                <h3>On-Facebook Listings Funnel</h3>
                <p class="mb-3">
                    A custom attribution overview design specifically for On-Facebook AIA playbooks. This funnel is
                    highly recommended when evaluation On-Facebook AIA specific campaigns.
                </p>
                <p class="mb-1 grey--text">
                    Funnel-specific metrics:
                </p>
                <ul class="mb-4">
                    <li class="caption mb-2">
                        <strong>{{ listingsDescriptions[0].metric }}</strong>: {{ listingsDescriptions[0].description }}
                    </li>
                    <li class="caption mb-2">
                        <strong>{{ listingsDescriptions[1].metric }}</strong>: {{ listingsDescriptions[1].description }}
                    </li>
                    <li class="caption mb-2">
                        <strong>{{ listingsDescriptions[2].metric }}</strong>: {{ listingsDescriptions[2].description }}
                    </li>
                </ul>
            </div>
        </div>
    </styled-slideout>
</template>

<script>
import StyledSlideout from '@/components/globals/StyledSlideout';

export default {
    name: 'AlertSlideOut',
    components: {
        StyledSlideout
    },
    props: {
        value: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            activeTab: 'opportunities',
            opportunityCount: 6,
            alertCount: 6,
            overallMetricDescriptions: [{
                metric: 'Prospects (Reach)',
                description: this.$t('metrics.reach')
            },{
                metric: 'On-Facebook Leads',
                description: this.$t('metrics.on_facebook_leads')
            },{
                metric: 'Off-Facebook Leads (Website Leads)',
                description: this.$t('metrics.off_facebook_leads')
            },{
                metric: 'Total Leads',
                description: this.$t('metrics.total_leads')
            }],
            defaultDescriptions: [{
                metric: 'Unique Landing Page Views',
                description: this.$t('metrics.unique_landing_page_views')
            }],
            allTrafficDescriptions: [{
                metric: 'Unique Clicks',
                description: this.$t('metrics.unique_clicks')
            }],
            listingsDescriptions: [{
                metric: 'Unique On-Facebook Content Views',
                description: this.$t('metrics.unique_on_site_content_views')
            },{
                metric: 'On-Facebook Phone Clicks',
                description: this.$t('metrics.on_facebook_phone_clicks')
            },{
                metric: 'On-Facebook Get Directions',
                description: this.$t('metrics.on_facebook_get_directions')
            }],
        };
    }
};
</script>