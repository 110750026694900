<template>
    <styled-card
        id="budget-pacing"
        class="flex ma-3 xs12"
        hide-toggle>
        <template #heading>
            Budget Pacing
        </template>
        <template
            v-if="!loading"
            #action-buttons>
            <action-button
                :href="adAccountSettings"
                target="_blank"
                icon="cog">
                BM Settings
            </action-button>
            <action-button
                :href="adAccountBilling"
                target="_blank"
                icon="money-sack">
                Payment Settings
            </action-button>
            <styled-tooltip>
                <template #content>
                    {{ status.tooltip }}
                </template>
                <action-button
                    icon="circle"
                    :color="status.color">
                    Status: {{ status.label }}
                </action-button>
            </styled-tooltip>
        </template>

        <div
            v-if="loading"
            class="ma-4">
            <loader />
        </div>

        <div
            v-else
            class="pacing">
            <div
                v-if="monthlyDataAvailable"
                class="monthly">
                <p class="title">
                    Monthly
                </p>
                <div class="data">
                    <div class="current budget">
                        <p class="label">
                            Budget
                        </p>
                        <styled-tooltip
                            position="top">
                            <template #content>
                                This budget represents the total monthly budget for the entire ad account and all calculations are using the total account spend, accordingly.
                            </template>
                            <p class="value">
                                <manage-dealer-budget
                                    :dealer="currentDealer"
                                    icon-size="20"
                                    no-data-label="set"
                                    class="edit-budget"
                                    @update="onEditBudget" />
                            </p>
                        </styled-tooltip>
                    </div>
                    <div
                        v-if="!!currentDealer.budget"
                        class="chart">
                        <apex-chart
                            type="bar"
                            :options="chartOptions"
                            :series="monthlySeries" />
                    </div>
                    <div
                        v-if="!!currentDealer.budget"
                        class="projected budget">
                        <p class="label">
                            Projection
                        </p>
                        <p class="value">
                            {{ budgetData.stats.projectedSpend.formatted }}
                        </p>
                        <p
                            v-if="!userIsClient"
                            class="indicator"
                            :class="status.color">
                            {{ spendDifferenceDescription }}
                        </p>
                    </div>
                </div>
            </div>
            <div
                v-else
                class="monthly no-data">
                <p>Monthly Budget pacing Data is not available</p>
            </div>
            <div
                v-if="dailyDataAvailable"
                class="daily">
                <p class="title">
                    Daily
                </p>
                <div class="data">
                    <div class="current budget">
                        <p class="label">
                            Current
                        </p>
                        <p class="value">
                            {{ budgetData.stats.dailyBudget.formatted }}
                        </p>
                        <p class="campaigns">
                            <styled-tooltip
                                position="top"
                                :max-width="500">
                                <template #content>
                                    <div id="campaign-tooltip">
                                        <p class="title">
                                            Budgeting is active for {{ campaignCount }} campaigns
                                        </p>
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th class="budget">
                                                        Daily Budget
                                                    </th>
                                                    <th class="campaign-name">
                                                        Campaign
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="(campaign,i) in budgetData.campaigns"
                                                    :key="i">
                                                    <td class="budget">
                                                        ${{ parseFloat(campaign.daily_budget/100).toFixed(2) }}
                                                    </td>
                                                    <td class="campaign-name">
                                                        {{ campaign.name }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </template>
                                <a :href="adAccount">{{ campaignCount }} Campaigns</a>
                            </styled-tooltip>
                        </p>
                    </div>
                    <div
                        v-if="!!currentDealer.budget"
                        class="chart">
                        <apex-chart
                            type="bar"
                            :options="chartOptions"
                            :series="dailySeries" />
                    </div>
                    <div
                        v-if="!!currentDealer.budget"
                        class="suggested budget">
                        <p class="label">
                            Suggested
                        </p>
                        <p class="value">
                            {{ budgetData.stats.suggestedDailyBudget.formatted }}
                        </p>
                    </div>
                    <div class="suggested adjustment">
                        <p
                            class="label"
                            :class="status.color">
                            Suggested <br>Adjustment
                        </p>
                        <p
                            class="value"
                            :class="status.color">
                            {{ budgetData.stats.suggestedDailyAdjustment.formatted }}
                        </p>
                        <p class="adjust-link">
                            <a
                                :href="adAccountSettings"
                                target="_blank">Adjust</a>
                        </p>
                    </div>
                </div>
            </div>
            <div
                v-else
                class="daily no-data">
                <p>Daily Budget pacing Data is not available</p>
            </div>
        </div>
    </styled-card>
</template>

<script>
import {
    PRODUCT_ADVERTISING,
    PRODUCT_ADVERTISING_PLUS,
} from '@/helpers/globals';
import BudgetingApi from '@/budgeting-api';
import ApexChart from 'vue-apexcharts';
import StyledTooltip from '@/components/globals/StyledTooltip';
import Loader from '@/components/globals/Loader';
import StyledCard from '@/components/globals/StyledCard';
import ManageDealerBudget from '@/components/globals/ManageDealerBudget';
import ActionButton from '@/components/globals/ActionButton';
import { mapState, mapGetters } from 'vuex';
import numeral from 'numeral';
import getNumberFormats from '@/helpers/numberFormats';
import { SET_DEALER } from '@/store/mutation-types';

export default {
    name: 'BudgetPacing',
    components: {
        StyledTooltip,
        Loader,
        ManageDealerBudget,
        StyledCard,
        ActionButton,
        ApexChart
    },
    data() {
        return {
            numberFormats: getNumberFormats(),
            loading: false,
            budgetData: {},
            chartOptions: {
                grid: {
                    show: false
                },
                colors: ['#00A2EA', '#ADF0FC'],
                legend: {
                    show: false
                },
                dataLabels: {
                    hideOverflowingLabels: true,
                    enabled: true,
                    style: {
                        colors: ['#5C5C5C'],
                        fontWeight: 400
                    },
                    formatter: (val) => {
                        return numeral(val).format(this.numberFormats.currency);
                    },
                    offsetY: -20
                },
                chart: {
                    height: 50,
                    type: 'bar',
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    bar: {
                        distributed: true,
                        horizontal: false,
                        dataLabels: {
                            position: 'top'
                        }
                    }
                },
                xaxis: {
                    show: false,
                    categories: ['Current', 'Suggested'],
                    labels: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    axisBorder: {
                        show: false
                    }
                },
                yaxis: {
                    show: false
                },
                tooltip: {
                    y: {
                        formatter: (val) => {
                            return numeral(val).format(this.numberFormats.currency);
                        }
                    }
                }
            }
        };
    },
    computed: {
        ...mapState({
            currentDealer: state => state.dealer.currentDealer,
            dateRange: state => state.metrics.dateRange,
            agency: (state) => state.agency.currentAgency
        }),
        ...mapGetters(['dealerHasProduct','userIsClient']),
        dealerHasAdsProduct() {
            return this.dealerHasProduct(PRODUCT_ADVERTISING) || this.dealerHasProduct(PRODUCT_ADVERTISING_PLUS);
        },
        adAccount() {
            return `https://business.facebook.com/adsmanager/manage/campaigns?act=${this.budgetData.adAccountId}&business_id=${this.agency.facebook_business_id}`;
        },
        adAccountSettings() {
                return `https://business.facebook.com/settings/ad-accounts/${this.budgetData.adAccountId}?business_id=${this.agency.facebook_business_id}`;
        },
        adAccountBilling() {
            return `https://business.facebook.com/ads/manager/account_settings/account_billing/?act=${this.budgetData.adAccountId}&business_id=${this.agency.facebook_business_id}&page=account_settings&tab=account_billing_settings`;
        },
        status() {
            if (!this.dealerHasAdsProduct) {
                return {
                    label: 'No Data',
                    color: 'gray',
                    tooltip: 'You do not have the Advertising Product.'
                };
            }
            if (!this.currentDealer.budget) {
                return {
                    label: 'No budget',
                    color: 'red',
                    tooltip: 'A monthly budget goal must be set for this account for budgeting to work'
                };
            }
            if (!this.monthlyDataAvailable && !this.dailyDataAvailable) {
                return {
                    label: 'No Data',
                    color: 'gray',
                    tooltip: 'No Data is available for the selected dealer and time frame'
                };
            }

            if (this.currentDealer.budget && !this.budgetData.campaigns.length) {
                return {
                    label: 'No campaigns',
                    color: 'red',
                    tooltip: 'There are currently no active campaigns matching the filters to budget'
                };
            }

            const {
                projectedSpendDifference: { value: difference }
            }  = this.budgetData.stats;

            if (difference >= .05) {
                return {
                    label: 'High Alert',
                    color: 'red',
                    tooltip: 'The current budget is pacing too far off acceptable thresholds'
                };
            }

            if ((difference < .05) && (difference > .01)) {
                return {
                    label: 'Warning',
                    color: 'yellow',
                    tooltip: 'The current budget is falling off pace and should be monitored closely'
                };
            }

            if (!this.monthlyDataAvailable && !this.dailyDataAvailable) {
                return {
                    label: 'No Data',
                    color: 'gray',
                    tooltip: 'No Data is available for the selected dealer and time frame'
                };
            }

            return {
                label: 'Pacing',
                color: 'green',
                tooltip: 'The current budget is within acceptable thresholds to meet the target monthly budget'
            };

        },
        monthlyDataAvailable() {
            return !!this.monthlySeries[0].data[0] && !!this.monthlySeries[0].data[1];
        },
        dailyDataAvailable() {
            return !!this.dailySeries[0].data[0] && !!this.dailySeries[0].data[1];
        },
        spendDifferenceDescription() {
            if (!this.budgetData) {
                return '';
            }

            const { value } = this.budgetData.stats.projectedSpendDifference;

            const percent = (Math.abs(value) * 100).toFixed(2);

            return `${percent}% ${(value > 0) ? 'over' : 'under'}`;
        },
        campaignCount() {
            return this.budgetData?.campaigns.length || 0;
        },
        monthlySeries() {
            return [{
                name: 'Budget',
                data: [
                    this.budgetData?.stats.monthlyBudget.value,
                    this.budgetData?.stats.projectedSpend.value
                ]
            }];
        },
        dailySeries() {
            return [{
                name: 'Budget',
                data: [
                    this.budgetData?.stats.dailyBudget.value,
                    this.budgetData?.stats.suggestedDailyBudget.value
                ]
            }];
        }
    },
    watch: {
        currentDealer() {
            this.loadData();
        },
        dateRange() {
            this.loadData();
        }
    },
    async created() {
        this.budgetingApi = new BudgetingApi();
        await this.loadData();
    },
    methods: {
        async loadData() {
            try {
                this.loading = true;
                this.budgetData = await this.budgetingApi.getDealerInsights({
                labels: [], // @todo add selector
                dealer: this.currentDealer
            });
            }catch (error) {
                console.error ('Error Loading Budget Pacing Data:', error.message || error);
            } finally {
                this.loading = false;
            }
        },
        async onEditBudget({ budget, dealer }) {


            dealer.budget = budget;

            this.$store.commit(SET_DEALER, dealer, { root: true });

            this.budgetData = await this.budgetingApi.getDealerInsights({
                labels: [], // @todo add selector
                dealer: this.currentDealer
            });
        },
    }
};
</script>

<style lang="scss">
$breakpoint: 1330px;
#campaign-tooltip {
    .title {
        font-size: 16px !important;
        font-weight: 600;
    }
    .table {
        .budget {
            text-align: center;
            white-space: nowrap;
            padding-right: 15px;
        }
        .campaign-name {
            text-align: left;
        }
    }
}
#budget-pacing {

    p {
        margin-bottom: 0;
    }
    .pacing {
        display: flex;
        flex-direction: column;

        @media (min-width: $breakpoint) {
            flex-direction: row;
        }
    }
    .monthly,
    .daily {
        height: 175px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: top;
        justify-content: center;
        align-items: center;

        @media (min-width: $breakpoint) {
            width: 50%;
        }
        .title {
            font-size: 18px !important;
            font-weight: 400;
            color: #00a2ea;
        }
        .data {
            display: flex;
            flex-direction: row;
            align-items: top;
            justify-content: center;
        }
    }
    .monthly {
        border-bottom: 1px solid #d8d8d8;

        @media (min-width: $breakpoint) {
            border-bottom: none;
            border-right: 1px solid #d8d8d8;
        }
    }
    .budget {
        padding: 15px;
        padding-top: 45px;
        text-align: center;

        .label {
            font-size: 12px;
            text-transform: uppercase;
        }
        .value {
            font-size: 30px;
            font-weight: 600;
            color: #00a2ea;
        }
        .indicator {
            margin-top: -5px;
            font-size: 15px;
            font-weight: 600;
            text-transform: uppercase;
            background: transparent !important;

            &.green {
                color: #a2cf75;
            }
            &.red {
                color: #f6685f;
            }
            &.yellow {
                color: #f8c41c;
            }
            &.gray {
                color: #8f9ea6;
            }
        }
    }
    .adjustment {
        padding: 15px;
        padding-top: 25px;
        text-align: center;

        .label {
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
            background: transparent !important;

            &.green {
                color: #a2cf75;
            }
            &.red {
                color: #f6685f;
            }
            &.yellow {
                color: #f8c41c;
            }
            &.gray {
                color: #8f9ea6;
            }
        }
        .value {
            font-size: 30px;
            font-weight: 600;
            color: #00a2ea;
            background: transparent !important;
            &.green {
                color: #a2cf75;
            }
            &.red {
                color: #f6685f;
            }
            &.yellow {
                color: #f8c41c;
            }
            &.gray {
                color: #8f9ea6;
            }
        }
        .adjust-link {
            margin-top: -5px;
        }
    }

    .chart {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 200px;
        min-width: 200px;
        height: 100%;
    }

    .edit-budget {
        display: inline-block;
        .budget-is-set a {
            color: #00a2ea;
            text-decoration: none;
        }
    }

    .no-data {
        padding-top: 50px;
    }
}
</style>